import React from 'react';
import { Link } from 'react-router-dom';
import plasma from '../images/gfc-therapy.jpg';
import laser from '../images/laser-therapy.jpg';
import chiro from '../images/chiropractory.jpg';
import faceCupping from '../images/facial-cupping.jpg';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Specialized In</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                          
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <img src={plasma} /> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">GFC</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    GFC stands for Growth Factor Concentrate. GFC is pure and doesn't contain any unwanted cells. It's based on delivering plasma containing highly concentrated growth factors from the patient's own blood.
                                    During the procedure, medical professionals apply growth factors directly to the hair roots by superficially injecting them into the scalp.
                                    A study conducted on a group of healthy males and females with androgenetic alopecia with GFC gave 83% positive results.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <img src={laser} /> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Laser Therapy</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    This method uses carbon dioxide to get rid of scars or wrinkles.
                                    CO2 laser resurfacing works by stimulating the collagen production in your skin. This helps to blend the surface of your skin better with normal skin around it.
                                    The results of CO2 laser resurfacing can last for anywhere between three and five years. Many patients maintain treatments by returning every so often for a touch-up or supplementary skin treatment.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <img src={chiro} /> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Chiropractic</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    A chiropractor is a health care professional focused on the diagnosis and treatment of neuromuscular disorders, with an emphasis on treatment through manual adjustment and/or manipulation of the spine.
                                    For many conditions, chiropractic treatment can restore the structural integrity of the spine, reduce pressure on the sensitive neurological tissue, and consequently improve the health of the individual.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <img src={faceCupping} /> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Facial Cupping</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Facial cupping increases blood flow to the area and rejuvenates the skin without leaving cup marks behind.
                                    Facial cupping has been shown to increase oxygen-rich blood circulation, strengthen skin and connective tissues & relax muscle tension.
                                    Because of this, the practice is said to brighten skin & minimize the appearance of scars, fine lines, and wrinkles.
                                </p>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;