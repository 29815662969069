import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import whatsappIcon from '../../images/whatsapp-icon.svg';
import brandLogo from '../../images/yashfeen_logo.png';


const NavBar = () => {
    const [top, setTop] = useState(true);
    const [isOpen, setisOpen] = React.useState(false);
    function handleClick() {
        setisOpen(!isOpen);
    }


    useEffect(() => {
      const scrollHandler = () => {
        window.pageYOffset > 10 ? setTop(false) : setTop(true)
      };
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <nav className={`fixed top-0 w-full h-16 z-30 transition duration-300 ease-in-out mb-16 z-50 ${!top && 'bg-white shadow-lg'}`}>
            {/* Large screen navbar */}
            <div className='hidden lg:inline-block w-full'>
                <div className="flex flex-row justify-between items-center py-2">
                    <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
                        <img src={brandLogo} className="w-12 mr-2" alt='Brand logo' />
                        <Link to="/"><h1 className="font-extrabold text-2xl text-blue-600">Yashfeen Cure</h1></Link>
                    </div>
                    <div className="group flex flex-col items-center">
                        {/* <div className='space-x-6 px-8'>
                            <HashLink className={`px-4 font-extrabold hover:text-blue-600 ${top ? 'text-black' : 'text-gray-500'}`} smooth to="/">
                                Home
                            </HashLink>
                            <HashLink className={`px-4 font-extrabold hover:text-blue-600 ${top ? 'text-black' : 'text-gray-500'}`} smooth to="/service">
                                Services
                            </HashLink>
                            <HashLink className={`px-4 font-extrabold hover:text-blue-600 ${top ? 'text-black' : 'text-gray-500'}`} to="/contact">
                                Contact Us
                            </HashLink>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* Small screen navbar */}
            <div className="flex flex-row justify-center items-center py-2 lg:hidden">
                <div className="flex flex-row items-center text-center font-semibold">
                    <img src={brandLogo} className="w-12 mr-2" alt='Brand logo' />
                    <Link to="/"><h1 className="font-extrabold text-2xl text-blue-600">Yashfeen Cure</h1></Link>
                </div>
                <div className="group flex flex-col items-center">
                    <button className="fixed toggle-button p-2 rounded-lg text-blue-900" onClick={handleClick}>
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen && (
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            )}
                            {!isOpen && (
                            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                    </button>

                    <div className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white rounded-lg block lg:hidden shadow-xl top-14 ${  isOpen ? "block" : "hidden" } `}>
                        <div className='flex flex-col space-y-6'>
                            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-600" onClick={handleClick} smooth to="/#about">
                                About
                            </HashLink>
                            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-600" onClick={handleClick} smooth to="/service">
                                Services
                            </HashLink>
                            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-600" onClick={handleClick} to="/contact">
                                Contact Us
                            </HashLink>
                        </div>                                                
                    </div>
                </div>
            </div>
            <a href="https://wa.me/919044449965?text=Hi there, I'm interested in learning more about your services." target='_blank'><img alt="Whatsapp icon" className="rounded-t group-hover:scale-[1.15] transition duration-300 ease-in-out shadow-xl appointment-button" src={whatsappIcon} /></a>
        </nav>
    )
    
}


export default NavBar;
