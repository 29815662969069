import { useState, useRef } from "react";
import { TextField, Button, Typography, Grid, Box } from "@mui/material";
import Navbar from '../components/Navbar/Navbar2';
import emailjs from '@emailjs/browser';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';

export default function ContactForm() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_2ajsib6', 'template_qemg8vd', form.current, 'fStttKpSLqFP_RFAu')
      .then(() => {
          alert('Email successfully sent');
      }, (error) => {
          alert(error.text);
      });
  };

  return (
    <>
        <Navbar />
        <Box sx={{ height: `calc(100vh-64px)`, marginTop: '64px' }}>
        {/* <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
        >
            <Grid item xs={12} md={4}>
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" align="center" mb={2}>
                Contact Us
                </Typography>
                <form > */}
                <Grid container spacing={2} sx={{ height: "100vh" }}>
                    <Grid item xs={12} md={6}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.43091555474!2d80.34771457487992!3d26.474066978804665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c3960dfe6b6b9%3A0xd02c6ed1e0a0688!2sYashfeen%20hijama%20and%20derma%20care!5e0!3m2!1sen!2sin!4v1706345018792!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} md={6} pr={2} sx={{ backgroundColor: "#F5F6F8", borderColor: '#6895D2', borderLeftWidth: '1px', borderTopWidth: '1px'}}>
                        <Typography variant="h4" align="center" mb={4} mt={2} className="text-blue-900">
                            Contact Us
                        </Typography>
                        <Grid container spacing={2} mb={4}>
                            <Grid item xs={6} md={6}>
                                <Typography variant="p" fontWeight={700} p={3} align="center" className="text-blue-800">
                                    <PlaceIcon fontSize="16px" color="action" className="mr-1" />
                                    ADDRESS
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Typography variant="p" align="center">
                                    Basement, Golden palace Apartment, 15/288, Opposite Bhargava Hospital, Civil Lines, Kanpur, Uttar Pradesh 208001
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={4}>
                            <Grid item xs={6} md={6}>
                                <Typography variant="p" fontWeight={700} p={3} align="center" className="text-blue-800">
                                    <PhoneIcon fontSize="16px" color="action" className="mr-1" />
                                    PHONE
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Typography variant="p" align="center">
                                    +91-9044449965
                                </Typography>
                            </Grid>
                        </Grid>
                        <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <a href="https://www.instagram.com/hijamakanpur" target='_blank' className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Instagram">
                                        {/* <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg> */}
                                        <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 30 30">
                                            <path d="M18 3H6C4.3 3 3 4.3 3 6v12c0 1.7 1.3 3 3 3h12c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3zm-6 6c1.7 0 3 1.3 3 3s-1.3 3-3 3s-3-1.3-3-3s1.3-3 3-3zm3.8-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2s-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2zM18 19H6c-.6 0-1-.4-1-1v-6h2c0 2.8 2.2 5 5 5s5-2.2 5-5h2v6c0 .6-.4 1-1 1z"/>
                                        </svg>
                                        </a>
                                    </li>
                                    <li className="ml-4">
                                        <a href="https://www.facebook.com/CUPPINGTHERAPYKANPUR" target='_blank' className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                        </svg>
                                        </a>
                                    </li>
                                </ul>
                        </div>
                        <form ref={form} onSubmit={handleSubmit} style={{paddingLeft: '8px'}}>
                            <TextField
                                fullWidth
                                name="user_name"
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="normal"
                                required
                            />
                            <TextField
                                fullWidth
                                name="user_email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                                required
                                type="email"
                            />
                            <TextField
                                fullWidth
                                name="message"
                                label="Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                margin="normal"
                                required
                                multiline
                                rows={4}
                            />
                            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                Submit
                            </Button>
                        </form>
                    </Grid>
                </Grid>
                {/* </form>
            </Box>
            </Grid>
        </Grid> */}
        </Box>
    </>
  );
}