import React from 'react';
import Typography from '@mui/material/Typography';

const WetCupping = () => {
  return (
    <div>
      <Typography variant="h4" fontWeight={700} mb={2} className='text-blue-900'>
        Wet Cupping
      </Typography>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Overview</Typography>
        <Typography paragraph>
          Wet cupping, also known as "Hijama," is an ancient traditional healing practice
          that involves creating suction on the skin using glass or plastic cups.
          It is a form of alternative therapy believed to promote blood flow,
          relieve pain, and eliminate harmful substances from the body.
        </Typography>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Procedure</Typography>
        <Typography paragraph className='mb-1'>
          The wet cupping procedure typically involves the following steps:
        </Typography>
        <ol className='list-decimal'>
          <li>Clean the skin where the cups will be applied.</li>
          <li>Create a vacuum by placing a cup on the skin, often using heat or a suction pump.</li>
          <li>Leave the cups in place for a few minutes to draw blood to the surface.</li>
          <li>Remove the cups and make small incisions on the skin.</li>
          <li>Apply the cups again to allow a controlled amount of blood to be drawn out.</li>
        </ol>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Benefits</Typography>
        <Typography paragraph className='mb-1'>
          Advocates of wet cupping claim that it may have various benefits, including:
        </Typography>
        <ul className='list-disc'>
          <li>Improved blood circulation</li>
          <li>Pain relief</li>
          <li>Detoxification</li>
          <li>Reduced inflammation</li>
          <li>Stress relief</li>
        </ul>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Precautions</Typography>
        <Typography paragraph>
          It's important to note that wet cupping should be performed by trained professionals
          in a sterile environment. Individuals with certain medical conditions or taking specific
          medications should consult with a healthcare provider before undergoing wet cupping.
        </Typography>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Conclusion</Typography>
        <Typography paragraph>
          Wet cupping is a practice deeply rooted in traditional medicine and cultural beliefs.
          While some people find it beneficial, it's essential to approach it with caution,
          ensuring it is performed safely and under appropriate conditions.
        </Typography>
      </section>
    </div>
  );
};

export default WetCupping;
