import React from 'react';
import Typography from '@mui/material/Typography';

const FireCupping = () => {
  return (
    <div>
      <Typography variant="h4" fontWeight={700} mb={2} className='text-blue-900'>
        Fire Cupping
      </Typography>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Overview</Typography>
        <Typography paragraph>
          Fire cupping is an ancient healing practice that involves creating a vacuum inside glass or
          bamboo cups using fire. The suction effect draws the skin upward into the cup, promoting
          blood flow and providing various health benefits.
        </Typography>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Procedure</Typography>
        <Typography paragraph className='mb-1'>
          The fire cupping procedure typically involves the following steps:
        </Typography>
        <ol className='list-decimal'>
          <li>Prepare the skin and glass or bamboo cups.</li>
          <li>Insert a cotton ball soaked in alcohol into the cup and set it on fire.</li>
          <li>Quickly place the cup on the skin, creating suction as the fire consumes oxygen.</li>
          <li>Leave the cups in place for the desired duration.</li>
          <li>Remove the cups to complete the session.</li>
        </ol>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Benefits</Typography>
        <Typography paragraph className='mb-1'>
          Fire cupping enthusiasts believe that it may offer several benefits, including:
        </Typography>
        <ul className='list-disc'>
          <li>Improved circulation and blood flow</li>
          <li>Relief from muscle tension and pain</li>
          <li>Detoxification through lymphatic drainage</li>
          <li>Stimulation of the body's energy flow (Qi)</li>
        </ul>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Precautions</Typography>
        <Typography paragraph>
          Fire cupping should be performed by trained practitioners to ensure safety. Individuals with
          certain health conditions or concerns, such as skin issues or pregnancy, should consult
          with a healthcare professional before undergoing fire cupping.
        </Typography>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Conclusion</Typography>
        <Typography paragraph>
          Fire cupping is a traditional therapy with a long history in various cultures. While some
          find it beneficial, it's crucial to approach fire cupping with caution and seek guidance
          from qualified practitioners.
        </Typography>
      </section>
    </div>
  );
};

export default FireCupping;
