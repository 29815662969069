import React from 'react';
import Typography from '@mui/material/Typography';

const DryCupping = () => {
  return (
    <div>
      <Typography variant="h4" fontWeight={700} mb={2} className='text-blue-900'>
        Dry Cupping
      </Typography>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Overview</Typography>
        <Typography paragraph>
        Dry cupping is a form of traditional therapy that involves placing suction cups
          on the skin without making any incisions. It is believed to stimulate blood flow,
          reduce inflammation, and promote healing.
        </Typography>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Procedure</Typography>
        <Typography paragraph className='mb-1'>
          The procedure for dry cupping includes the following steps:
        </Typography>
        <ol className='list-decimal'>
          <li>Clean the skin where the cups will be applied.</li>
          <li>Create a vacuum by placing a cup on the skin, often using heat or a suction pump.</li>
          <li>Leave the cups in place for a specific duration.</li>
          <li>Remove the cups to complete the session.</li>
        </ol>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Benefits</Typography>
        <Typography paragraph className='mb-1'>
          Advocates suggest that dry cupping may offer benefits such as:
        </Typography>
        <ul className='list-disc'>
          <li>Improved circulation</li>
          <li>Relief from muscle tension</li>
          <li>Enhanced detoxification</li>
          <li>Promotion of relaxation</li>
        </ul>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Precautions</Typography>
        <Typography paragraph>
          While dry cupping is generally considered safe, individuals with certain medical conditions
          or skin conditions should consult with a healthcare provider before trying dry cupping.
        </Typography>
      </section>

      <section className='mb-3'>
        <Typography variant="h5" fontWeight={600} mb={1} className='text-blue-800'>Conclusion</Typography>
        <Typography paragraph>
          Dry cupping is a non-invasive therapy that can be a part of holistic wellness practices.
          As with any alternative therapy, it's crucial to approach it mindfully and seek professional advice.
        </Typography>
      </section>
    </div>
  );
};

export default DryCupping;
