import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ServicesIcon from '@mui/icons-material/MedicalServices';
import ContactIcon from '@mui/icons-material/Mail';
import { HashLink } from 'react-router-hash-link';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import brandLogo from '../images/yashfeen_logo.png';
import whatsappIcon from '../images/whatsapp-icon.svg';
import DryCupping from './ServicesComponents/DryCupping';
import WetCupping from './ServicesComponents/WetCupping';
import FireCupping from './ServicesComponents/FireCupping';
import BambooCupping from './ServicesComponents/BambooCupping';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: 'white',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: 'white',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const ComponentForSelectedService = ({ selectedServiceItem }) => {
  switch (selectedServiceItem) {
    case 'Wet Cupping':
      return <WetCupping />;
    case 'Dry Cupping':
      return <DryCupping />;
    case 'Bamboo Cupping':
      return <BambooCupping />;
    case 'Fire Cupping':
      return <FireCupping />;
    default:
      return <></>;
  }
};

export default function ServicesTabUI() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedService, setSelectedService] = React.useState('Wet Cupping');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" className='bg-white shadow-lg' open={open}>
        <Toolbar>
            <span className='flex items-center justify-between w-full'>
                <span className='flex items-center'>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={brandLogo} className="w-12 mr-2" alt='Brand logo' />
                    <Link to="/"><h1 className="font-bold text-2xl text-blue-600">Yashfeen Cure</h1></Link>
                    {/* <Typography color={"black"} variant="h6" noWrap component="div">
                        Yashfeen Cure
                    </Typography> */}
                </span>
                <div className="group flex flex-col items-center hidden lg:inline-block">
                    <div className='space-x-6 px-8'>
                        <HashLink className='px-4 font-semibold hover:text-blue-600 text-gray-500' smooth to="/">
                            Home
                        </HashLink>
                        <HashLink className='px-4 font-semibold hover:text-blue-600 text-gray-500' smooth to="/service">
                            Services
                        </HashLink>
                        <HashLink className='px-4 font-semibold hover:text-blue-600 text-gray-500' to="/contact">
                            Contact Us
                        </HashLink>
                    </div>
                </div>
            </span>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <CloseIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        <Accordion>
            <AccordionItem>
                {({open}) => (
                        <>
                            <AccordionHeader className='w-full flex items-center text-gray-600 border-b py-4 pl-4'>
                                <ChevronRightIcon className={`w-7 h-7 transform transition duration-300 ${!open ? '' : ' rotate-90'}`} />
                                <span className="ml-2">Cupping Therapy</span>
                            </AccordionHeader>

                            <AccordionBody className='pl-2'>
                                <div className="accordion-body">
                                    {['Wet Cupping', 'Dry Cupping', 'Bamboo Cupping', 'Fire Cupping'].map((text, index) => (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton onClick={() => setSelectedService(text)}>
                                                <ListItemText primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </div>
                            </AccordionBody>
                        </>
                    )}
            </AccordionItem>
            <AccordionItem>
                {({open}) => (
                        <>
                            <AccordionHeader className='w-full flex items-center text-gray-600 border-b py-4 pl-4'>
                                <ChevronRightIcon className={`w-7 h-7 transform transition duration-300 ${!open ? '' : ' rotate-90'}`} />
                                <span className="ml-2">Dermatology</span>
                            </AccordionHeader>

                            <AccordionBody className='pl-2'>
                                <div className="accordion-body">
                                    <AccordionItem>
                                        {({open}) => (
                                            <>
                                                <AccordionHeader className='w-full flex items-center text-gray-600 border-b py-4 pl-4'>
                                                    <ChevronRightIcon className={`w-7 h-7 transform transition duration-300 ${!open ? '' : ' rotate-90'}`} />
                                                    <span className="ml-2">Skin Treatments</span>
                                                </AccordionHeader>

                                                <AccordionBody className='pl-2'>
                                                    <div className="accordion-body">
                                                    <AccordionItem>
                                                        {({open}) => (
                                                            <>
                                                                <AccordionHeader className='w-full flex items-center text-gray-600 border-b py-4 pl-4'>
                                                                    <ChevronRightIcon className={`w-7 h-7 transform transition duration-300 ${!open ? '' : ' rotate-90'}`} />
                                                                    <span className="ml-2">Lazer Therapy</span>
                                                                </AccordionHeader>

                                                                <AccordionBody className='pl-2'>
                                                                    <div className="accordion-body">
                                                                        {['Tattoo Removal', 'Dark Spots Removal', 'Carbon Lazer'].map((text, index) => (
                                                                            <ListItem key={text} disablePadding>
                                                                            <ListItemButton>
                                                                                <ListItemText primary={text} />
                                                                            </ListItemButton>
                                                                            </ListItem>
                                                                        ))}
                                                                    </div>
                                                                </AccordionBody>
                                                            </>
                                                        )}
                                                    </AccordionItem>
                                                    {['PRP', 'Cocktail', 'Micropigmentation', 'Mole Removal', 'Hydra Facial'].map((text, index) => (
                                                        <ListItem key={text} disablePadding>
                                                        <ListItemButton>
                                                            <ListItemText primary={text} />
                                                        </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                    </div>
                                                </AccordionBody>
                                            </>
                                        )}
                                    </AccordionItem>
                                    <AccordionItem>
                                        {({open}) => (
                                            <>
                                                <AccordionHeader className='w-full flex items-center text-gray-600 border-b py-4 pl-4'>
                                                    <ChevronRightIcon className={`w-7 h-7 transform transition duration-300 ${!open ? '' : ' rotate-90'}`} />
                                                    <span className="ml-2">Hair Treatments</span>
                                                </AccordionHeader>

                                                <AccordionBody className='pl-2'>
                                                    <div className="accordion-body">
                                                        {['GFC', 'Cocktail', 'Cupping', 'Micro Needling', 'Hair Transplant'].map((text, index) => (
                                                            <ListItem key={text} disablePadding>
                                                            <ListItemButton>
                                                                <ListItemText primary={text} />
                                                            </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </div>
                                                </AccordionBody>
                                            </>
                                        )}
                                    </AccordionItem>
                                </div>
                            </AccordionBody>
                        </>
                    )}
            </AccordionItem>
        </Accordion>
        </List>
        <List className='lg:hidden'>
          {[{name: 'Home', route: '/'}, { name: 'Services', route: '/service'}, {name: 'Contact Us', route: '/contact'}].map((routeObj) => (
            <ListItem key={routeObj.name} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {routeObj.name === 'Home' ? <HomeIcon /> : routeObj.name === 'Services' ? <ServicesIcon /> : <ContactIcon />}
                </ListItemIcon>
                <HashLink smooth to={routeObj.route}>
                    <ListItemText primary={routeObj.name} />
                </HashLink>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open} style={{backgroundColor: '#F5F6F8'}}>
        <DrawerHeader />
        <ComponentForSelectedService selectedServiceItem={selectedService} />
      </Main>
      <a href="https://wa.me/919044449965?text=Hi there, I'm interested in learning more about your services." target='_blank'><img alt="Whatsapp icon" className="rounded-t group-hover:scale-[1.15] transition duration-300 ease-in-out shadow-xl appointment-button z-50" src={whatsappIcon} /></a>
    </Box>
  );
}