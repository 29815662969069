import React, { useState } from 'react';
import ServicesTabUI from '../components/ServicesTabUI';
import Footer from '../components/Footer';
import Hero2 from '../components/Hero2';
import CuppingTherapy from '../components/CuppingTherapy';
import Dermatology from '../components/Dermatology';

const Service = () => {
    
    return (
        <>
            <ServicesTabUI />
            {/* <Hero2 />
            <div id="cupping-therapy" className="bg-gray-100 py-12" >
                <CuppingTherapy />
                <Dermatology />
            </div>
            <Footer /> */}
        </>


    )
}

export default Service;