import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ServicesIcon from '@mui/icons-material/MedicalServices';
import ContactIcon from '@mui/icons-material/Mail';
import { HashLink } from 'react-router-hash-link';
import brandLogo from '../../images/yashfeen_logo.png';
import whatsappIcon from '../../images/whatsapp-icon.svg';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: 'white',
    transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: 'white',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Navbar2() {
  const theme = useTheme();
  const [top, setTop] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" className='transition duration-300 ease-in-out mb-16 z-50' open={open}>
        <Toolbar>
            <span className='flex items-center justify-between w-full'>
                <span className='flex items-center'>
                    <div className="lg:hidden">
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </div>
                    <img src={brandLogo} className="w-12 mr-2" alt='Brand logo' />
                    <Link to="/"><h1 className="font-bold text-2xl text-blue-600">Yashfeen Cure</h1></Link>
                    {/* <Typography color={"black"} variant="h6" noWrap component="div">
                        Yashfeen Cure
                    </Typography> */}
                </span>
                <div className="group flex flex-col items-center hidden lg:inline-block">
                    <div className='space-x-6 px-8'>
                        <HashLink className='px-4 font-semibold hover:text-blue-600 text-gray-500' smooth to="/">
                            Home
                        </HashLink>
                        <HashLink className='px-4 font-semibold hover:text-blue-600 text-gray-500' smooth to="/service">
                            Services
                        </HashLink>
                        <HashLink className='px-4 font-semibold hover:text-blue-600 text-gray-500' to="/contact">
                            Contact Us
                        </HashLink>
                    </div>
                </div>
            </span>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <CloseIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className='lg:hidden'>
          {[{name: 'Home', route: '/'}, { name: 'Services', route: '/service'}, {name: 'Contact Us', route: '/contact'}].map((routeObj) => (
            <ListItem key={routeObj.name} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {routeObj.name === 'Home' ? <HomeIcon /> : routeObj.name === 'Services' ? <ServicesIcon /> : <ContactIcon />}
                </ListItemIcon>
                <HashLink smooth to={routeObj.route}>
                    <ListItemText primary={routeObj.name} />
                </HashLink>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <a href="https://wa.me/919044449965?text=Hi there, I'm interested in learning more about your services." target='_blank'><img alt="Whatsapp icon" className="rounded-t group-hover:scale-[1.15] transition duration-300 ease-in-out shadow-xl appointment-button z-50" src={whatsappIcon} /></a>
    </Box>
  );
}