import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';
import shakeelImg from '../images/Profile-pic.jpg';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-full float-right" src={shakeelImg} width={200} height={200} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-blue-900 font-bold">Dr. Mohammad Shakeel</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>B.p.T, D.Cu.T (United Kingdom), Chiropractor</p>
                            </div>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>PGDCC, Cosmetologist</p>
                            </div>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Rich experience of 7 years</p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;